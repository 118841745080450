import { useState, useEffect } from "react";

import './Courses.css';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { SetListPag } from "interface/Page";

import { SvgListline, SvgListSquare, SvgSearch, SvgTriagle } from "components/SvgFile";
import ListCourses from "./ListCourses";

export default function Page_Courses(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('courses'));
    const [ nameUser, setNameUser ] = useState(GetUserData('user'));

    const [ idCurse, setIdCurse ] = useState(InitialData('id'));
    const [ title, setTitle ]     = useState(InitialData('title'));
    const [ text, setText ]       = useState(InitialData('text'));
    const [ file, setFile ]       = useState(InitialData('img'));
    
    useEffect(()=>{
        RegisterUserData('user', setNameUser); 
        RegisterDataPage('courses', setDataPage);

        document.title = 'Cursos';
    }, []);

    function CkickPage(value){        
        SetListPag('currentPageDash_id', value); 
    }

    function InitialData(type){
        const newData = dataPage.find(item => item.highlight == true);
        return newData[type];
    }

    return(
        <div className="Page_Courses">
            <div className="slideshow">
                <div className="container div_slideshow">
                    {
                        Object.keys(nameUser).length != "" ? 
                        <>
                            <div className="list_icons highlight_icons">
                                <div className="show_names">
                                    <div className="highlight_subtitle">
                                        Novo curso de
                                    </div>
                                    <div className="highlight_title">
                                        { title }
                                    </div>
                                    <div className="highlight_text" dangerouslySetInnerHTML={ { __html: text.replaceAll('&#34;', '"') } } />
                                    <div className="button">
                                        <a href={ "/#show_course" } onClick={ ()=>{ CkickPage(idCurse) } }>
                                            <div className="button_text">
                                                Assista agora
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="show_img">
                                <img alt="img" src={ file } className="img_courses" />
                            </div>
                        </> :
                        <>
                            <div className="list_icons">
                                <div className="show_icons">
                                    <div className="div_show_icon">
                                        <div className="">
                                            <img alt="img" src="./assets/icon_3.png" className="show_icons_slide" />
                                        </div>
                                        <div className="width_icon">Assista os vídeos</div>
                                    </div>
                                    <div className="div_show_icon">
                                        <div className="">
                                            <img alt="img" src="./assets/icon_2.png" className="show_icons_slide" />
                                        </div>
                                        <div className="width_icon">Execute os exercícios</div>
                                    </div>
                                    <div className="div_show_icon">
                                        <div className="">
                                            <img alt="img" src="./assets/icon_1.png" className="show_icons_slide" />
                                        </div>
                                        <div className="width_icon">Tire dúvidas pelo Discord</div>
                                    </div>
                                </div>
                                <div className="button">
                                    <a href={ "/howworks" } onClick={ ()=>{ CkickPage("howworks") } }>
                                        <div className="button_text">
                                            Como funciona
                                            {/* Assista agora */}
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="show_img">
                                <img alt="img" src="./assets/cursos.png" className="img_courses" />
                            </div>
                        </>
                    }
                </div>
            </div>
            <ListCourses />
        </div>
    )
}