import Axios from 'axios';

import Cookies from 'universal-cookie';

import { cookiesRegister, cookie_email, cookie_passw, cookie_site, cookie_dash_id } from 'dataFixed';

import { SetListPag }  from 'interface/Page.js';
import { SetUserData } from 'interface/Users.js';
import { SetListData } from 'interface/Data';

export function Access(type, reg_email, reg_pass, CallbackSuccess, CallbackError){ 
    const cookies = new Cookies(); 

    const data    = new FormData();
    data.append('logar', 'logar');
    data.append('type', type);
    data.append('email', reg_email);
    data.append('password', reg_pass);
    
    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/ReturnData/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        switch (response.data.access_type) {
            case "erro":
                    CallbackError();
                    cookies.set(cookie_site, 'login', '/', cookiesRegister);

                    cookies.remove(cookie_passw, "", '/', cookiesRegister);
                    cookies.remove(cookie_email, "", '/', cookiesRegister);
                break;

            default:       
            
                    if(cookies.get(cookie_site)){ 
                        let cookiesPages_ = cookies.get(cookie_site); 
                        SetListPag('page', cookiesPages_); 
                    }else {
                        cookies.set(cookie_site, 'login', '/', cookiesRegister);
                        SetListPag('page', 'login');
                    }
                    
                    if(cookies.get(cookie_dash_id)){
                        let cookiesID = cookies.get(cookie_dash_id); 
                        SetListPag('currentPageDash_id', cookiesID); 
                    }

                    cookies.set(cookie_passw, response.data.user.pass, '/', cookiesRegister);
                    cookies.set(cookie_email, response.data.user.email, '/', cookiesRegister);

                    SetUserData('user', response.data.user);    
                    SetUserData('access_type', response.data.access_type); 
                    CallbackSuccess();
                break;
        }
    }).catch((error)=>{
        CallbackError();
        cookies.set(cookie_site, 'login', '/', cookiesRegister);
        
        cookies.remove(cookie_passw, "", '/', cookiesRegister);
        cookies.remove(cookie_email, "", '/', cookiesRegister);
    })
}

export function Reg_AltPhoto(file, CallbackSuccess, CallbackError){ 

    const cookies    = new Cookies();    
    let return_pass  = cookies.get(cookie_passw);
    let return_email = cookies.get(cookie_email);

    const data = new FormData();
    data.append('logar', 'alt_photo');

    data.append('file', file);
    data.append('email', return_email);
    data.append('password', return_pass);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        switch (response.data.access_type) {
            case "erro":
                    CallbackError();
                break;
            
            default:
                    SetUserData('user', response.data.user);     
                    SetUserData('access_type', response.data.access_type); 
                    CallbackSuccess(); 
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}

export function Reg_AltData(name, login, email, phone, file, country, address, cep, city, uf, cpf, pass, CallbackSuccess, CallbackError){ 

    const cookies    = new Cookies();    
    let return_pass  = cookies.get(cookie_passw);
    let return_email = cookies.get(cookie_email);

    const data = new FormData();
    data.append('logar', 'alt_data');

    data.append('name', name);
    data.append('login', login);
    data.append('email', email);
    data.append('phone', phone);
    data.append('file', file);
    data.append('country', country);
    data.append('address', address);
    data.append('cep', cep);
    data.append('city', city);
    data.append('uf', uf);
    data.append('cpf', cpf);
    data.append('pass', pass);

    data.append('email', return_email);
    data.append('password', return_pass);
    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        switch (response.data.access_type) {
            case "erro":
                    CallbackError();
                break;
            
            default:
                    cookies.set(cookie_passw, response.data.user.pass, '/', cookiesRegister);
                    cookies.set(cookie_email, response.data.user.email, '/', cookiesRegister);

                    SetUserData('user', response.data.user);     
                    SetUserData('access_type', response.data.access_type); 
                    CallbackSuccess(); 
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}

export function Reg_ResetPassw(password, code, CallbackSuccess, CallbackError){ 
    const data = new FormData();
    data.append('logar', 'reset_passw');

    data.append('password', password);
    data.append('number', code);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        switch (response.data) {
            case "ok!":
                    CallbackSuccess(); 
                break;
            default:
                    CallbackError();
                break;
        }       

    }).catch((error)=>{
        CallbackError();
    })
}