import { useState, useEffect, useRef } from "react";

import './Mentoring.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";

import ModelPages_Home                from "components/ModelPages/Home";
import ModelPages_Payment             from "components/ModelPages/Payment";
import ModelPages_Depositions         from "components/ModelPages/Depositions";
import ModelPages_StudentWorks        from "components/ModelPages/StudentWorks";
import ModelPages_TeacherProfile      from "components/ModelPages/TeacherProfile";
import ModelPages_ModulesWorkload     from "components/ModelPages/ModulesWorkload";
import ModelPages_VideoPresentation   from "components/ModelPages/VideoPresentation";
import ModelPages_ExplanationContents from "components/ModelPages/ExplanationContents";
import ModelPages_PersonalizedService from "components/ModelPages/PersonalizedService";

export default function Page_Mentoring(props){

    const heightPage = useRef()

    const [ dataPage, setDataPage ]       = useState(GetDataPage('mentoring'));
    const [ payment, setPayment ]         = useState(GetDataPage('payment'));
    const [ showPayment, setShowPayment ] = useState(CheckedPayment('mentoring'));
    const [ totalHeight, setTotalHeight ] = useState(0);

    useEffect(()=>{
        RegisterDataPage('mentoring', setDataPage);
        RegisterDataPage('payment', setPayment);

        document.title = 'Mentoria';

        CheckedHeigth();
    }, []);

    function CheckedPayment(type){
        // const newData = payment.page.find(item => item == type);
        // if(newData){
        //     return <ModelPages_Payment CkickPage={ props.CkickPage } />;
        // }
        return '';
    }

    function TypeDataPage(value, index){
        switch(value){
            case "Home":
                return <ModelPages_Home type="mentoring" index={ index } />;

            case "Apresentação com vídeo":
                return <ModelPages_VideoPresentation type="mentoring" index={ index } />;
            
            case "Trabalhos de alunos":
                return <ModelPages_StudentWorks type="mentoring" index={ index } />;
            
            case "Explicação dos conteúdos":
                return <ModelPages_ExplanationContents type="mentoring" index={ index } />;
            
            case "Atendimento personalizado":
                return <ModelPages_PersonalizedService type="mentoring" index={ index } />;
            
            case "Perfil do professor":
                return <ModelPages_TeacherProfile type="mentoring" index={ index } />;
            
            case "Módulos e carga horária":
                return <ModelPages_ModulesWorkload type="mentoring" index={ index } />;

            case "Depoimentos":
                return <ModelPages_Depositions type="mentoring" index={ index } />;

            // case "paymenttypes":
            //     return <PaymentTypes type="mentoring" index={ index } />;
        }
    }

    function CheckedHeigth(){
        if(document.querySelector('.Page_Rolo')){
            if(heightPage.current.clientHeight == 300){
                setTotalHeight(1);
            }else {
                setTotalHeight(0);
            }
        }
    }
    
    return(
        <div className={ totalHeight == 1 ? "Page_Mentoring Page_Mentoring_height" : "Page_Mentoring" } ref={ heightPage }>
            {
                Object.keys(dataPage).map((key, index)=>{
                    return(
                        <div className="show_data_page" key={ index }>
                            {
                                TypeDataPage(dataPage[key].type, index)
                            }
                        </div>
                    )
                })
            }
            {
                showPayment
            }
        </div>
    )
}