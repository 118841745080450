// Connection type
// const pageServidor = 'http://localhost/React_JS/3_crazy_pixel/site_v3/public';
const pageServidor = '.';
export const connectionPage = pageServidor;

// Cookies type
// const cookiesServidor = 'localhost';
const cookiesServidor = 'dashboardsitemodelo1.fastevolution.com.br/';
export const cookiesRegister = cookiesServidor;

// Cookies name
export const cookie_site  = 'modelo_1_site';
export const cookie_page  = 'modelo_1_page';
export const cookie_passw = 'modelo_1_passw';
export const cookie_email = 'modelo_1_email';

export const cookie_dash           = 'modelo_1_dash';
export const cookie_dash_id        = 'modelo_1_dash_id';
export const cookie_dash_id_classe = 'modelo_1_dash_id_classe';

export const cookie_type_payment       = 'modelo_1_payment';
export const cookie_type_stage_payment = 'modelo_1_stage';