import { useState, useEffect, useRef } from "react";

import Cookies from 'universal-cookie';

import { Access } from "services/AccessLogin";

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { cookie_site, cookie_email, cookie_passw } from "dataFixed";

import FixedTopic from "FixedTopic";

import FixedFooter from "FixedFooter";

import Page_Rolo from "components/Pages/Rolo";
import Page_Home from "components/Pages/Home";
import Page_Login from "components/Pages/Login";
import Page_Courses from "components/Pages/Courses";
import Page_HowWorks from "components/Pages/HowWorks";
import Page_Mentoring from "components/Pages/Mentoring";

export default function App() {

    const cookies = new Cookies();

    const [ currentPage, setCurrentPage ] = useState(GetListPag('page')); 

    useEffect(()=>{     
        RegisterListPag('page', setCurrentPage);

        NamePageUrl();
        
        if(cookies.get(cookie_passw) || cookies.get(cookie_email)){
            let return_pass  = cookies.get(cookie_passw);
            let return_email = cookies.get(cookie_email);
            Access('return', return_email, return_pass, ()=>{ }, ()=>{ })
        }
    }, []);

    function ReturnTop(){
        window.scrollTo(0, 0);
    }

    function NamePageUrl(){
        if(cookies.get(cookie_site)){
            let idPage = cookies.get(cookie_site);
            if(idPage == 'undefined'){
                SetListPag('page', 'index');
            }else {
                switch (idPage) {
                    case "index": case "howworks": case "mentoring": case "courses": case "rolo": case "login": case "enrollment": case "payment": case "typepayment": case "payment_approved_prata": case "payment_refused_prata": case "logout": case "recover":
                            SetListPag('page', idPage);
                        break;
                
                    default:
                            SetListPag('page', 'index');
                        break;
                }
                
            }
        }else {
            SetListPag('page', 'index');
        }
    }

    function CkickPage(value){   
        ReturnTop();        
        window.scrollTo(0, 0);        
        switch (value) {
            case "index": case "howworks": case "mentoring": case "courses": case "rolo": case "login":
                    SetListPag('page', value);   
                    CurrentPage(value);
                break;
        
            default: 
                    SetListPag('remuve', 'All');
                    
                    CurrentPage('index');
                    SetListPag('page', 'index');   
                break;
        }
    }

    function CurrentPage(value){
        switch(value){
            case "index":  
                return <Page_Home CkickPage={ CkickPage } />;

            case "howworks": 
                return <Page_HowWorks CkickPage={ CkickPage } />;

            case "courses":  
                return <Page_Courses CkickPage={ CkickPage } />;

            case "mentoring":  
                return <Page_Mentoring CkickPage={ CkickPage } />;

            case "rolo":   
                return <Page_Rolo CkickPage={ CkickPage } />;

            case "login": case "logout": 
                return <Page_Login CkickPage={ CkickPage } />;
            
            // case "enrollment":
            //     return <Page_Enrollment CkickPage={ CkickPage } />;
            
            // case "payment":
            //     return <Page_Payment CkickPage={ CkickPage } />;
            
            // case "typepayment":
            //     return <Page_TypePayment origin="0" CkickPage={ CkickPage } />;
            
            // case "payment_approved_prata":
            //     return <Page_ReturnPayment_Approved CkickPage={ CkickPage } />;
            
            // case "payment_refused_prata":
            //     return <Page_ReturnPayment_Refused CkickPage={ CkickPage } />;

            // case "recover": case "recover=" + numberRecover + "":
            //     return <Page_RecoverPass code={ numberRecover } CkickPage={ CkickPage } />;

            default:
                return <Page_Home CkickPage={ CkickPage } />;
        }
    }

    return (
        <div className="show_content">
            <div className="whatsapp">
                <a href="https://wa.me/message/IUIG2IOYGP5WF1" target="_blank">
                    <img alt="Whatsapp" src="./assets/whatsapp.svg" className="icon_whatsapp" />
                </a>
            </div>
            <FixedTopic CkickPage={ CkickPage } />
            {
                currentPage == 'login' ? null : <div className="space_topic" />
            }   
            {
                CurrentPage(currentPage)
            }   
            <FixedFooter CkickPage={ CkickPage } />
        </div>
    );
}