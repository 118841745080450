
let UserData = {
    "user"        : {},
    "access_type" : ""  
};

let NotifyUserData = {
    "user"        : [],
    "access_type" : []
}

export function SetUserData(key, value){
    UserData[key] = value;
    NotifyUserData[key].forEach(element => {
        element(value);
    });
}
export function GetUserData(key){
    return UserData[key];   
}
export function RegisterUserData(key, value){
    if(!NotifyUserData[key]){
        NotifyUserData[key] = []; 
    }
    NotifyUserData[key].push(value);
}