import { useState, useEffect } from "react";

import './ListCourses.css';

import Cookies from 'universal-cookie';

import { SvgListSquare, SvgListline, SvgSearch, SvgTriagle } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";
import PopUP_CheckedCourse from "components/PopUp/CheckedCourse";

import { Access } from "services/AccessLogin";
import { SetListPag } from "interface/Page";
import { cookie_email, cookie_passw } from "dataFixed";

export default function ListCourses(props){

    const cookies = new Cookies();

    const [ dataPage, setDataPage ] = useState(GetDataPage('courses'));
    const [ nameUser, setNameUser ] = useState(GetUserData('user'));

    const [ listCategory, setListCategory ] = useState(ListCategory());
    const [ typeList, setTypeList ]         = useState(false);
    const [ showCategory, setShowCategory ] = useState(false);
    const [ catSelected, setCatSelected ]   = useState('');

    const [ listCourses, setListCourses ] = useState(dataPage);

    // const [ itensPage, setItensPage ] = useState(30);
    // const [ showPage, setShowPage ]   = useState(0);

    // const page         = Math.ceil(listCourses.length / itensPage);
    // const startItens   = showPage * itensPage;
    // const endItens     = startItens + itensPage;
    // const currentItens = listCourses.slice(startItens, endItens);
    
    // useEffect(()=>{
    //     RegisterUserData('user', setNameUser); 
    //     RegisterDataPage('courses', setDataPage);
    // }, []);

    function ListCategory(){
        const newData = [];
        GetDataPage('list_opt_select').forEach(item =>{
            if(item.type == 0){
                newData.push(item);
            }
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        return duplicate;
    }
    
    function ShowText(type, text){
        if(type == 0){
            if(text.length > 290){
                return text.substring(0, 290).replaceAll('<br><br>', '<br>') + "...";
            }
        }else if(type == 1){
            if(text.length > 65){
                return text.substring(0, 65) + "...";
            }
        }
        return text;
    }

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('courses').forEach(item =>{
                if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });     
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListCourses(duplicate);
        }else {
            setListCourses(GetDataPage('courses'));
        }
        setCatSelected('Mostrar todas as categorias');
        setShowCategory(false);
    }

    function SearchCategory(value){
        if(value == 'Mostrar todas as categorias'){
            setListCourses(GetDataPage('courses'));
            setCatSelected(value);
        }else if(value){
            const newList = [];
            GetDataPage('courses').forEach(item =>{
                item.category.forEach(item_1 =>{                    
                    if(item_1.name == value){
                        newList.push(item);
                    } 
                }); 
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListCourses(duplicate);
            setCatSelected(value);
        }else {
            setListCourses(GetDataPage('courses'));
            setCatSelected(value);
        }
        setShowCategory(false);
    }

    function OpenCourses(id, type, video, title){
        if(cookies.get(cookie_passw) || cookies.get(cookie_email)){
            let return_pass  = cookies.get(cookie_passw);
            let return_email = cookies.get(cookie_email);
            Access('return', return_email, return_pass, ()=>{ CallbackSuccess(id) }, ()=>{ CallbackError(type, video, title) })
        }else {
            SetModalData("CheckedCourse", { "type": type, "video": video, "title": title });
            SetModalState('CheckedCourse', true);
        }
    } 

    function CallbackSuccess(id){
        SetListPag('currentPage', 'login');
        SetListPag('currentPageDash', 'dash_classe_detalis');
        SetListPag('currentPageDash_id', id);
    }

    function CallbackError(type, video, title){
        SetModalData("CheckedCourse", { "type": type, "video": video, "title": title });
        SetModalState('CheckedCourse', true);
    }

    console.log(listCourses);

    return(
        <div className="container list_data_courses">
            <div className="show_search">
                <div className="show_icons_list" onClick={ ()=>{ setTypeList(!typeList) } }>
                    {
                        typeList ? 
                        <SvgListline color="#193C4E" className="show_type_icon" /> :
                        <SvgListSquare color="#193C4E" className="show_type_icon" />
                    }
                </div>
                <div className="div_search">
                    <SvgSearch color="#ffffff" className="icons_search" />
                    <input type="text" className="input_search" onChange={ (e)=>{ SearchInput(e.target.value) } } />
                </div>
                <div className="div_select">
                    <div className="select_category" onClick={ ()=>{ setShowCategory(!showCategory) } }>
                        <div className="">
                            {
                                catSelected !='' ? catSelected : "Mostrar todas as categorias"
                            }
                        </div>
                        <div className="show_type_triagle">
                            {
                                showCategory ? 
                                <SvgTriagle color="#FFFFFF" className="triagle triagle_active"/> :
                                <SvgTriagle color="#FFFFFF" className="triagle"/>
                            }
                        </div>
                    </div>
                    {
                        showCategory ? 
                        <div className="list_category">
                            <div className="show_name_category" onClick={ ()=>{ SearchCategory("Mostrar todas as categorias"); } }>
                                Mostrar todas as categorias
                            </div>
                            {
                                listCategory.map((key, index)=>{
                                    return(
                                        <div className="show_name_category" key={ index } onClick={ ()=>{ SearchCategory(key.name); } }>
                                            { key.name }
                                        </div>
                                    )
                                })
                            }
                        </div> : ""
                    }
                </div>
            </div>

            <div className={ typeList ? "list_courses_line" 
                : 
                listCourses.length < 4 ? "list_courses_square alt_posit" : "list_courses_square" }>
                {
                    listCourses.length >0 ?
                    listCourses.map((key, index)=>{
                        return(
                            <a className={ typeList ? "list_line" : "list_square" } key={ index } onClick={ ()=>{ OpenCourses(key.id, key.type_video, key.video, key.title) } }>
                                <div className="courses_img">
                                    <img alt="img" src={ key.file } className="img_"/>
                                </div>
                                <div className={ typeList ? "list_line_courses_data" : "list_square_courses_data" }>
                                    { key.title }
                                    <div className={ typeList ? "courses_text" : "courses_text text_align" } dangerouslySetInnerHTML={ { __html: typeList ? ShowText(0, key.text.replaceAll('&#34;', '"')) : ShowText(1, key.text.replaceAll('&#34;', '"')) } } />
                                </div>
                            </a>
                        )
                    }) 
                    : <div className="no_data">Nenhum curso encontrado...</div>
                }
            </div>
            <PopUP_CheckedCourse />
        </div>
    )
}