import { useState, useEffect, useRef } from "react";

import './StudentWorks.css';

import { GetDataPageID, RegisterDataPage } from "interface/Data";

export default function ModelPages_StudentWorks(props){
    
    const textHeight                = useRef();   
    const [ showData, setShowData ] = useState(GetDataPageID(props.type, props.index));
    const [ height, setHeight ]     = useState(0);
    const [ checked, setChecked ]   = useState(0)
    
    useEffect(()=>{
        RegisterDataPage(props.type, setShowData);
    }, []);
    
    useEffect(()=>{
        setShowData(GetDataPageID(props.type, props.index));
    }, [showData]);
    
    useEffect(()=>{
        setInterval(() => {
            setChecked(1);
            if(textHeight?.current){
                setHeight(textHeight?.current.clientHeight + 60);
                window.onresize = function(){
                    setChecked(1);
                    setHeight(textHeight?.current.clientHeight + 60);
                };
            }
        }, 100);   
    }, [checked]); 

    return(
        <div className="ModelPages_StudentWorks" style={ { height: (height + 60) + 'px', background: showData.bg_color } }>
            <div className="container show_data">
                <div className="show_video">
                    {
                        showData.title_1 ? <div className="title">{ showData.title_1 }</div> : null
                    }                    
                    <div className="show_video" ref={ textHeight }>  
                        {
                            showData.video_type == "Vimeo" ?
                            <iframe className="div_video" src={ "https://player.vimeo.com/video/" + showData.video_code + "?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0" } frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" />
                            :
                            <iframe className="div_video" src={ "https://www.youtube.com/embed/" + showData.video_code + "?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0" } frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" />
                        }                    
                    </div>
                </div>
            </div>
            <div className="background_image" style={ { backgroundImage: "url('" + showData.bg_img + "')" } }>
                {/* <img alt="img" src={ showData.bg_page } className="img_home" /> */}
            </div>
        </div>
    )
}