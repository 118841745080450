import Axios from 'axios';

import Cookies from 'universal-cookie';

import { cookie_email, cookie_passw } from 'dataFixed';

import { SetUserData } from 'interface/Users';

export function Reg_AttendedClasses(idUser, idCourse, idClasses, idModule){ 

    const cookies = new Cookies();    
    const data = new FormData();

    data.append('list_inf', 'register_attended_classes');
    data.append('type_data', 'registered_classe');

    data.append('idUser', idUser);
    data.append('idCourse', idCourse);
    data.append('idClasses', idClasses);
    data.append('idModule', idModule);

    data.append('email', cookies.get(cookie_email));
    data.append('password', cookies.get(cookie_passw));

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/RegisterData/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        SetUserData('user', response.data.user);     
        SetUserData('access_type', response.data.access_type); 
    }).catch((error)=>{
    })
}

export function Reg_ClassesFinished(idUser, idCourse, idClasses, idModule, status){ 

    const cookies = new Cookies();    
    const data = new FormData();

    data.append('list_inf', 'register_attended_classes');
    data.append('type_data', 'classe_finished');

    data.append('idUser', idUser);
    data.append('idCourse', idCourse);
    data.append('idClasses', idClasses);
    data.append('idModule', idModule);
    data.append('status', status);

    data.append('email', cookies.get(cookie_email));
    data.append('password', cookies.get(cookie_passw));

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/RegisterData/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        SetUserData('user', response.data.user);     
        SetUserData('access_type', response.data.access_type); 
    }).catch((error)=>{
    })
}

export function Reg_ClassesUploadFile(idUser, idCourse, idClasses, file, CallbackSuccess, CallbackError){ 

    const cookies = new Cookies();    
    const data = new FormData();

    data.append('list_inf', 'register_attended_classes');
    data.append('type_data', 'classe_upload_file');

    data.append('idUser', idUser);
    data.append('idCourse', idCourse);
    data.append('idClasses', idClasses);
    data.append('file', file);

    data.append('email', cookies.get(cookie_email));
    data.append('password', cookies.get(cookie_passw));

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/RegisterData/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        SetUserData('user', response.data.user);     
        SetUserData('access_type', response.data.access_type);
        CallbackSuccess(); 
    }).catch((error)=>{
        CallbackError();
    })
}

export function Reg_User(typePayment, name, email, login, birthDate, phone, country, address, cep, city, uf, cpf, pass_1, CallbackSuccess, CallbackError, Emailexists){ 
    const data = new FormData();
    data.append('list_inf', 'register_user');
    
    data.append('name', name);
    data.append('typePayment', typePayment);
    data.append('email', email);
    data.append('login', login);
    data.append('birthDate', birthDate);
    data.append('phone', phone);
    data.append('country', country);
    data.append('address', address);
    data.append('cep', cep);
    data.append('city', city);
    data.append('uf', uf);
    data.append('cpf', cpf);
    data.append('pass_1', pass_1);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/RegisterData/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        switch (response.data) {
            case 'E-mail existente':   
                    Emailexists();                      
                break;
            case 'Erro ao enviar mensagem':  
                    CallbackError();             
                break;
            default:
                    CallbackSuccess();           
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}

export function Reg_RecoverEmail(email, CallbackSuccess, CallbackError_Send, CallbackError_NotEmail, CallbackError){ 
    const data = new FormData();
    data.append('list_inf', 'recover_email');
    data.append('email', email);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/RegisterData/recover_pass.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        console.log(response.data);
        switch (response.data) {
            case "Email enviado com sucesso":
                    CallbackSuccess();
                break;
            case "Email não enviado":
                    CallbackError_Send();
                break;
            case "E-mail não encontrado":
                    CallbackError_NotEmail();
                break;
            case "Erro ao enviar mensagem":
                    CallbackError();
                break;
        }
    }).catch((error)=>{
        CallbackError();
    })
}

export function Reg_Conclusion(idUser, idPage, conclusionText, conclusionQuestion, CallbackSuccess, CallbackError){ 

    const cookies = new Cookies();    
    const data = new FormData();

    data.append('list_inf', 'register_conclusion');

    data.append('idUser', idUser);
    data.append('idCourse', idPage);
    data.append('conclusionText', conclusionText);
    conclusionQuestion.forEach(element => {
        data.append('conclusion_id[]', element.id);
        data.append('conclusion_opt[]', element.optSelected);
    });

    data.append('email', cookies.get(cookie_email));
    data.append('password', cookies.get(cookie_passw));

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/RegisterData/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        
        SetUserData('user', response.data.user);     
        SetUserData('access_type', response.data.access_type);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

export function Reg_ClassesFinishedPorcentagem(idUser, idCourse, idClasses){ 

    const cookies = new Cookies();    
    const data = new FormData();

    data.append('list_inf', 'register_attended_classes');
    data.append('type_data', 'classe_finished_porcent');

    data.append('idUser', idUser);
    data.append('idCourse', idCourse);
    data.append('idClasses', idClasses);
    
    data.append('email', cookies.get(cookie_email));
    data.append('password', cookies.get(cookie_passw));

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/RegisterData/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        SetUserData('user', response.data.user);     
        SetUserData('access_type', response.data.access_type); 
    }).catch((error)=>{
    })
}