import React, { useState, useEffect } from "react";

import './CheckedCourse.css';

import { SvgClose } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SetModalState, RegisterModalData, RegisterModalObserver, GetModalData } from "interface/PopUp";
import { SetListPag } from "interface/Page";

export default function PopUP_CheckedCourse(){

    const [ modalData, setModaldata ]     = useState({});
    const [ showPopUp, setShowPopUp ]     = useState(false);

    const [ type, setType ]   = useState('');
    const [ video, setVideo ] = useState('');
    const [ title, setTitle ] = useState('');

    useEffect(()=>{
        RegisterModalData('CheckedCourse', setModaldata);
        RegisterModalObserver('CheckedCourse', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setType(modalData.type);
            setVideo(modalData.video);
            setTitle(modalData.title);
        }
    }, [showPopUp]);

    function ClosePopUp(){
        SetModalState('CheckedCourse', false);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all CheckedCourse">
                        <div className="div_data">
                            
                            <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                                <SvgClose color="#ffffff" className="icons" />
                            </div> 
                           
                            <div className="content">
                                <div className="pop_title">{ title }</div>
                                <div className="pop_video">
                                    {
                                        type == "Vimeo" ?
                                        <iframe className="div_video" src={ "https://player.vimeo.com/video/" + video } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen /> :
                                        <iframe className="div_video" src={ "https://www.youtube.com/embed/" + video } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />
                                    }
                                </div>
                                <div className="pop_title">Assista o curso completo com os Planos de acesso anual</div>
                                <div className="pop_text">Com nossos planos você poderá acessar, cursos, arquivos, interagir com os professores e ainda poder participar do projeto Rolo</div>
                                <a className="pop_div_button" href="/mentoring">
                                    <div onClick={ ()=>{ SetListPag('currentPage', "mentoring"); } }>
                                        <div className="pop_button">
                                            Saiba Mais
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}