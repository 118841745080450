import { useState, useEffect, useRef } from "react";

import './Home.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";

import ModelPages_Home                from "components/ModelPages/Home";
import ModelPages_Payment             from "components/ModelPages/Payment";
import ModelPages_Depositions         from "components/ModelPages/Depositions";
import ModelPages_StudentWorks        from "components/ModelPages/StudentWorks";
import ModelPages_TeacherProfile      from "components/ModelPages/TeacherProfile";
import ModelPages_ModulesWorkload     from "components/ModelPages/ModulesWorkload";
import ModelPages_VideoPresentation   from "components/ModelPages/VideoPresentation";
import ModelPages_ExplanationContents from "components/ModelPages/ExplanationContents";
import ModelPages_PersonalizedService from "components/ModelPages/PersonalizedService";

export default function Page_Home(props){

    const heightPage = useRef()

    const [ dataPage, setDataPage ]       = useState(GetDataPage('home'));
    const [ payment, setPayment ]         = useState(GetDataPage('payment'));
    const [ showPayment, setShowPayment ] = useState(CheckedPayment('home'));
    const [ totalHeight, setTotalHeight ] = useState(0);

    useEffect(()=>{
        RegisterDataPage('home', setDataPage);
        RegisterDataPage('payment', setPayment);

        document.title = 'Home';
        CheckedHeigth();
    }, []);

    function CheckedPayment(type){
        // const newData = payment.find(item => item == type);
        // if(newData){
        //     return <ModelPages_Payment CkickPage={ props.CkickPage } />;
        // }
        return '';
    }

    function TypeDataPage(value, index){
        switch(value){
            case "Home":
                return <ModelPages_Home type="home" index={ index } CkickPage={ props.CkickPage } />;

            case "Apresentação com vídeo":
                return <ModelPages_VideoPresentation type="home" index={ index } CkickPage={ props.CkickPage } />;
            
            case "Módulos e carga horária":
                return <ModelPages_ModulesWorkload type="home" index={ index } CkickPage={ props.CkickPage } />;
            
            case "Atendimento personalizado":
                return <ModelPages_PersonalizedService type="home" index={ index } CkickPage={ props.CkickPage } />;
            
            case "Perfil do professor":
                return <ModelPages_TeacherProfile type="home" index={ index } CkickPage={ props.CkickPage } />;
            
            case "Trabalhos de alunos":
                return <ModelPages_StudentWorks type="home" index={ index } CkickPage={ props.CkickPage } />;
            
            case "Explicação dos conteúdos":
                return <ModelPages_ExplanationContents type="home" index={ index } CkickPage={ props.CkickPage } />;

            case "Depoimentos":
                return <ModelPages_Depositions type="home" index={ index } CkickPage={ props.CkickPage } />;
        }
    }

    function CheckedHeigth(){
        if(document.querySelector('.Page_Home')){
            if(heightPage.current.clientHeight == 300){
                setTotalHeight(1);
            }else {
                setTotalHeight(0);
            }
        }
    }
    
    return(
        <div className={ totalHeight == 1 ? "Page_Home Page_Home_height" : "Page_Home" } ref={ heightPage }>
            {
                Object.keys(dataPage).map((key, index)=>{
                    return(
                        <div className="show_data_page" key={ index } style={ { background: dataPage[key].bg_color } }>
                            {
                                TypeDataPage(dataPage[key].type, index)
                            }
                        </div>
                    )
                })
            }
            {
                showPayment
            }
        </div>
    )
}