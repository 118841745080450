
import Cookies from 'universal-cookie';
import { cookiesRegister, cookie_site, cookie_dash, cookie_dash_id, cookie_type_payment, cookie_type_stage_payment, cookie_dash_id_classe } from 'dataFixed';

const cookies = new Cookies(); 

let ListPag = {
    "page"               : "index",
    "pageDash"           : "course",
    "pageDash_id_course" : 0,
    "pageDash_id_classe" : 0,
    "typePayment"        : "Free",
    "stagePayment"       : 0,
    "remuve"             : ""
};

let NotifyListPag = {
    "page"               : [],
    "pageDash"           : [],
    "pageDash_id_course" : [],
    "pageDash_id_classe" : [],
    "typePayment"        : [],
    "stagePayment"       : [],
    "remuve"             : []
}

export function SetListPag(key, value){    
    ListPag[key] = value;
    NotifyListPag[key].forEach(element => {
        element(value);
    });

    let minutesValid = new Date(new Date().getTime() + 60 * 60 * 2000);
    if(key == 'page'){
        cookies.set(cookie_site, value, { path: '/', expires: minutesValid }, cookiesRegister);
    }

    if(key == 'remuve'){
        if(value == 'All'){
            cookies.remove(cookie_type_payment, '', { path: '/', expires: minutesValid }, cookiesRegister);
        }
        cookies.remove(cookie_type_stage_payment, '', { path: '/', expires: minutesValid }, cookiesRegister);
    }

    if(key == 'typePayment'){
        cookies.set(cookie_type_payment, value, { path: '/', expires: minutesValid }, cookiesRegister);
    }
    if(key == 'stagePayment'){
        cookies.set(cookie_type_stage_payment, value, { path: '/', expires: minutesValid }, cookiesRegister);
    }

    if(key == "pageDash"){
        cookies.set(cookie_dash, value, { path: '/', expires: minutesValid }, cookiesRegister); 
    }

    if(key == "pageDash_id_course"){
        if(value == 'remuve'){
            cookies.remove(cookie_dash_id, '', { path: '/', expires: minutesValid }, cookiesRegister); 
        }else {
            cookies.set(cookie_dash_id, value, { path: '/', expires: minutesValid }, cookiesRegister); 
        }
    }

    if(key == "pageDash_id_classe"){
        if(value == 'remuve'){
            cookies.remove(cookie_dash_id_classe, '', { path: '/', expires: minutesValid }, cookiesRegister); 
        }else {
            cookies.set(cookie_dash_id_classe, value, { path: '/', expires: minutesValid }, cookiesRegister); 
        }
    }
}

export function GetListPag(key){
    return ListPag[key];
}

export function RegisterListPag(key, value){
    if(!NotifyListPag[key]){
        NotifyListPag[key] = []; 
    }
    NotifyListPag[key].push(value);
}