import { useState, useEffect } from "react";

import './VideoPresentation.css';

import { GetDataPageID, RegisterDataPage } from "interface/Data";

export default function ModelPages_VideoPresentation(props){

    const [ showData, setShowData ] = useState(GetDataPageID(props.type, props.index));
    
    useEffect(()=>{
        RegisterDataPage(props.type, setShowData);
    }, []);
    
    useEffect(()=>{
        setShowData(GetDataPageID(props.type, props.index));
    }, [showData]);

    function ShowButton(type, destiny, title){
        switch (type) {
            case 'Interno':
                return(
                    <a href={ "/#" + destiny }>
                        <div className="button_text" onClick={ ()=>{ props.CkickPage(destiny) } }>
                            { title }
                        </div>
                    </a>
                )

            case 'Externo':
                return(
                    <a href={ destiny }>
                        <div className="button_text">
                            { title }
                        </div>
                    </a>
                )
        }
    }

    return(
        <div className="ModelPages_VideoPresentation" style={ { background: showData.bg_page } }>
            <div className="container show_data">
                {
                    showData.video_code == '' ? null : 
                    <div className="show_video">
                        {
                            showData.video_type == "Vimeo" ?
                            <iframe className="div_video" src={ "https://player.vimeo.com/video/" + showData.video_code + "?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0" } frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" />
                            :
                            <iframe className="div_video" src={ "https://www.youtube.com/embed/" + showData.video_code + "?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0" } frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" />
                        } 
                    </div>
                }
                <div className={ showData.video == '' ? "div_text div_text_full" : "div_text" }>
                    <div className="div_title">
                        {
                            showData.title_1 ? <div className={ showData.video == '' ? "subtitle subtitle_center" : "subtitle" }>{ showData.title_1 }</div> : null
                        }
                        {
                            showData.title_2 ? <div className="title">{ showData.title_2 }</div> : null
                        }
                    </div>
                    {
                        showData.text_1 ? <div className="text" dangerouslySetInnerHTML={ { __html: showData.text_1.replaceAll('&#34;', '"') } } /> : null
                    }                    
                    {
                        showData.title_3 ? <div className="subtitle">{ showData.title_3 }</div> : null
                    }
                    {
                        showData.text_2 ? <div className="text" dangerouslySetInnerHTML={ { __html: showData.text_2.replaceAll('&#34;', '"') } } /> : null
                    }        
                    { ShowButton(showData.type_destiny, showData.destiny, showData.button) }
                </div>
            </div>
        </div>
    )
}