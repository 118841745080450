import { useState, useEffect, useRef } from "react";

import './ModulesWorkload.css';

import { GetDataPageID, RegisterDataPage } from "interface/Data";

export default function ModelPages_ModulesWorkload(props){

    const textWidth    = useRef();
    const textHeight_1 = useRef();
    const textHeight_2 = useRef();

    const [ showData, setShowData ] = useState(GetDataPageID(props.type, props.index));
    const [ height, setHeight ]     = useState(0);
    const [ checked, setChecked ]   = useState(0)
    
    useEffect(()=>{
        RegisterDataPage(props.type, setShowData);
    }, []);
    
    useEffect(()=>{
        setInterval(() => {
            setChecked(1);
            if(textWidth?.current){
                if(textWidth?.current.clientWidth <= 960){
                    setHeight(textHeight_1?.current.clientHeight + textHeight_2?.current.clientHeight);
                }else {
                    setHeight(0);
                }
            }
        }, 100);   
    }, [checked]); 

    return(
        <div className="ModelPages_ModulesWorkload" ref={ textWidth } style={ { background: showData.bg_color } }>
            <div className="container show_data" style={ { minHeight: height } }>
                <div className="bg_img">
                    <img alt="02" src={ showData.img_example } ref={ textHeight_1 } className="img" />
                </div>
                <div className="div_text" ref={ textHeight_2 }>
                    <div className="title">{ showData.title_1 }</div>
                    <div className="table">
                        <table width="100%" cellPadding="2" cellSpacing="6">
                            <tbody>
                                {
                                    showData.contents.map((key, index)=>{
                                        return(
                                            <tr key={ index }>
                                                <td className="table_numb" style={ { backgroundColor: key.text } }>{ index + 1 }</td>
                                                <td className="table_text" style={ { color: key.text } }>{ key.name }</td>
                                                <td className="table_time" style={ { backgroundColor: key.text } }>{ key.date }h</td>
                                            </tr>
                                        )
                                    })
                                }
                                {/* 
                                <tr>
                                    <td colSpan="3" align="right">
                                        <span className="total_hour">Total: </span> 
                                        <span className="hour">{ showData.total_hour }h</span>
                                    </td>
                                </tr> 
                                */}
                            </tbody>
                        </table>
                    </div>
                    {
                        showData.text_1 ? 
                        <div className="time">
                            <div className="" dangerouslySetInnerHTML={ { __html: showData.text_1.replaceAll('&#34;', '"') } } />
                        </div> : null
                    }
                </div>
            </div>
        </div>
    )
}