import { useState, useEffect, useRef } from "react";

import './TeacherProfile.css';

import { GetDataPageID, RegisterDataPage } from "interface/Data";

export default function ModelPages_TeacherProfile(props){

    const textHeight = useRef();

    const [ showData, setShowData ] = useState(GetDataPageID(props.type, props.index));
    const [ height, setHeight ]     = useState(0);
    const [ checked, setChecked ]   = useState(0);
    
    useEffect(()=>{
        RegisterDataPage(props.type, setShowData);
    }, []);
    
    useEffect(()=>{
        setInterval(() => {
            setChecked(1);
            if(textHeight?.current){
                setHeight(textHeight?.current.clientHeight + 60);
                window.onresize = function(){
                    setChecked(1);
                    setHeight(textHeight?.current.clientHeight + 60);
                };
            }
        }, 100);   
    }, [checked]); 

    function ShowDataProfile(){
        return(
            <div className="show_data">
                <div className="name_profile">                        
                    <div className="">
                        <img alt="" src={ showData.file } className="img_profile" />
                    </div>      
                    <div className="div_name">
                        <span className={ showData.line_up == "right" ? "type_2_name" : "type_1_name" }>{ showData.btn_1_name }</span>
                        <span className="subname">{ showData.btn_2_name }</span>
                    </div>
                </div>
                <div className="text" dangerouslySetInnerHTML={ { __html: showData.text_1.replaceAll('&#34;', '"') } } />
                <div className="portfolio text">             
                    <div className="">Portfólio</div>  
                    {
                        showData.contents.map((key, index)=>{
                            return(         
                                <div className="" key={ index }>
                                    <a href={ key.text } target="_blank">
                                        <img alt="" src={ key.file } className="icons_" />
                                    </a>
                                </div>  
                            )
                        })
                    }   
                </div>
            </div>
        )
    }

    function ShowDataFile(){
        return(
            <div className="file">    
                <img alt="" src={ showData.img_example } className="img_bg" />
            </div>
        )
    }
    
    return(
        <div className="ModelPages_TeacherProfile" style={ { background: showData.bg_img, height: height + 'px', backgroundColor: showData.bg_color } }>
            <div className="container show_profile" ref={ textHeight }>
                {
                    showData.line_up == "right" ? 
                    <>
                        { ShowDataFile(showData.type_align) }
                        { ShowDataProfile(showData.type_align) }
                    </> :   
                    <>
                        { ShowDataProfile(showData.type_align) }
                        { ShowDataFile(showData.type_align) }
                    </>   
                }
            </div>
        </div>
    )
}