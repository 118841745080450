import { useState, useEffect, useRef } from "react";

import './FixedTopic.css';

import { SvgClose, SvgMenu, SvgUser } from "components/SvgFile";

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

export default function FixedTopic(props){

    const checkedWidth = useRef();
    const [ currentWidth, setCurrentWidth ] = useState(1200);

    const [ namePage, setNamePage ]          = useState(GetListPag('page'));
    const [ showMenuName , setShowMenuName ] = useState(GetDataPage('menuName'));

    const [ dataUser, setDataUser ] = useState(GetUserData('user'));
    const [ typeUser, setTypeUser ] = useState(GetUserData('access_type'));    
    const [ status, setStatus ]     = useState(false);

    useEffect(()=>{
        RegisterListPag('page', setNamePage);
        RegisterDataPage('menuName', setShowMenuName);

        RegisterUserData('user', setDataUser);
        RegisterUserData('access_type', setTypeUser);

        CheckedWidth();
    }, []);
    
    useEffect(()=>{
        setDataUser(GetUserData('user'));
        setTypeUser(GetUserData('access_type'));
    }, [dataUser]);

    function AccessUser(){
        switch (dataUser.nivel) {
            case "0": case 0:
                return (
                    <span style={ { color:'#97C107' } }>{ typeUser }</span>
                );
            case "1": case 1:
                return (
                    <span style={ { color:'#A8662D' } }>{ typeUser }</span>
                );
            case "2": case 2:
                return (
                    <span style={ { color:'#D3D3D3' } }>{ typeUser }</span>
                );
            case "3": case 3:
                return (
                    <span style={ { color:'#FFB800' } }>{ typeUser }</span>
                );
        }
    }
    
    function ShowPageSite(value){
        if(value == "Login"){
            if(dataUser.login){
                return(
                    <div className="data_user">
                        <div className="">
                            {
                                dataUser.file !='' ?
                                <img alt="login" src={ "./assets/login/" + dataUser.file } className="icon_menu_profile"/> :
                                <SvgUser color="#FFFFFF" className="icon_menu_profile" />
                            }
                        </div>
                        <div className="show_data_login">
                            <div className="">{ dataUser.login }</div>
                            <div className="type_access">{ AccessUser() }</div>
                        </div>
                    </div>
                )
            }else {
                return value;
            }
        }else {
            return value;
        }
    }

    function OpenMenu(value){
        setStatus(value);
        if(value == true){
            if(document.querySelector('.div_menu')){
                document.querySelector('.div_menu').classList.add('open_menu');
                document.querySelector('.div_menu').classList.remove('close_menu');
            }
        }else {
            if(document.querySelector('.div_menu')){
                document.querySelector('.div_menu').classList.add('close_menu');
                document.querySelector('.div_menu').classList.remove('open_menu');
                setTimeout(() => {
                    document.querySelector('.div_menu').classList.remove('close_menu');
                }, 100);
            }
        }
    }

    function CheckedWidth(){
    }

    return(
        <header className="FixedTopic" ref={ checkedWidth }>
            <div className="container ListMenu">
                <a href="/#index" onClick={ ()=>{ props.CkickPage('index'); OpenMenu(false); } }>
                    <div className="div_logo">
                        <img src="./logo192.png" className="logotipo" />
                    </div>
                </a>
                <div className="mobile" onClick={ ()=>{ OpenMenu(!status); } }>
                    {
                        status == false ? 
                        <SvgMenu color="#FFFFFF" className="icon_menu" /> : 
                        <SvgClose color="#FFFFFF" className="icon_menu" />
                    }
                </div>
                
                <div className="div_menu">
                    {
                        Object.keys(showMenuName).map((key, index)=>{
                            if(showMenuName[key].page == 'blog'){
                                // return(
                                //     <a className="show_opt_menu" href="https://blog.crazypixel.com.br/" target="_blank" key={ index }>
                                //         <div className="name_menu blog">{ showMenuName[key].name }</div>
                                //     </a>
                                // )
                            }else if(showMenuName[key].page == 'login'){
                            }else {
                                return(                                        
                                    <a className={ namePage == showMenuName[key].page ? "show_opt_menu menu_active" : "show_opt_menu" } href={ "/" + showMenuName[key].page } key={ index } onClick={ ()=>{ props.CkickPage(showMenuName[key].page); setStatus(false); } }>
                                        <div className="name_menu">
                                            {
                                                ShowPageSite(showMenuName[key].name)
                                            }
                                        </div>
                                    </a>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </header>
    )
}