import { useState, useEffect } from "react";

import './Home.css';

import { GetDataPageID, RegisterDataPage } from "interface/Data";

export default function ModelPages_Home(props){
    
    const [ showData, setShowData ] = useState(GetDataPageID(props.type, props.index));
    
    useEffect(()=>{
        RegisterDataPage(props.type, setShowData);
    }, []);
    
    useEffect(()=>{
        setShowData(GetDataPageID(props.type, props.index));
    }, [showData]);

    function ShowButton(type, destiny, title){
        switch (type) {
            case 'Interno':
                    switch (destiny) {
                        case 'enrollment':
                            return(
                                <a href={ "/mentoring" }>
                                    <div className="button_text" onClick={ ()=>{ props.CkickPage('mentoring') } }>
                                        { title }
                                    </div>
                                </a>
                            )

                        default:
                            return(
                                <a href={ "/" + destiny }>
                                    <div className="button_text" onClick={ ()=>{ props.CkickPage(destiny) } }>
                                        { title }
                                    </div>
                                </a>
                            )
                    }
                
            case 'Externo':
                return(
                    <a href={ destiny } onClick={ ()=>{ props.CkickPage(destiny) } }>
                        <div className="button_text">
                            { title }
                        </div>
                    </a>
                )
        }
    }

    return(
        <div className="ModelPages_Home">
            <div className="show_text">
                {
                    showData.title_1 ? <div className="title">{ showData.title_1 }</div> : null
                }                
                <div className="div_highlight">
                    {
                        showData.title_2 ? <div className="text">{ showData.title_2 }</div> : null
                    }
                    {
                        showData.title_3 ? <div className="highlight">{ showData.title_3 }</div> : null
                    }                   
                    <div className="list_text">
                        {
                            showData.title_4 ? <div className="text">{ showData.title_4 }</div> : null
                        }  
                        {
                            showData.title_5 ? <div className="text">{ showData.title_5 }</div> : null
                        }  
                        {
                            showData.title_6 ? <div className="text">{ showData.title_6 }</div> : null
                        }  
                    </div>
                </div>
                <div className="button">
                    {
                        showData.btn_1_name == '' ? null : ShowButton(showData.btn_1_type, showData.btn_1_destiny, showData.btn_1_name)
                    }
                    {
                        showData.btn_2_name == '' ? null : ShowButton(showData.btn_2_type, showData.btn_2_destiny, showData.btn_2_name)
                    }
                </div>
            </div>
            <div className="background_image">
                <img alt="img" src={ showData.bg_img } className="img_home" />
            </div>
        </div>
    )
}