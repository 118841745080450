import React, { useState, useEffect, useRef } from "react";

import './PersonalizedService.css';

import { GetDataPageID, RegisterDataPage } from "interface/Data";

export default function ModelPages_PersonalizedService(props){

    const textHeight   = useRef();    
    const widthCurrent = useRef();

    const [ showData, setShowData ] = useState(GetDataPageID(props.type, props.index));
    const [ height, setHeight ]     = useState(0);
    const [ checked, setChecked ]   = useState(0)
    const [ width, setWidth ]       = useState(false);
    
    useEffect(()=>{
        RegisterDataPage(props.type, setShowData);
    }, []);
    
    useEffect(()=>{
        setInterval(() => {
            setChecked(1);
            if(textHeight?.current){
                setHeight(textHeight?.current.clientHeight + 60);
                window.onresize = function(){
                    setChecked(1);
                    setHeight(textHeight?.current.clientHeight + 60);
                };
            }
        }, 100);   
    }, [checked]); 

    return(
        <div className="ModelPages_PersonalizedService" ref={ widthCurrent } style={ { height: height + 'px', background: showData.bg_color } }>
            <div className="container show_data">
                <div className="div_text" ref={ textHeight }>     
                    {
                        showData.title_1 ? <div className="title">{ showData.title_1 }</div> : null
                    }
                    {
                        showData.title_2 || showData.text_1 ?          
                        <div className="">     
                            {
                                showData.title_2 ? <div className="subtitle">{ showData.title_2 }</div> : null
                            } 
                            {
                                showData.text_1 ?                        
                                <div className="text" dangerouslySetInnerHTML={ { __html: showData.text_1.replaceAll('&#34;', '"') } } /> : null
                            }           
                        </div> : null
                    }     
                    {
                        showData.title_3 || showData.text_2 ?          
                        <div className="">    
                            {
                                showData.title_3 ? <div className="subtitle">{ showData.title_3 }</div> : null
                            } 
                            {
                                showData.text_2 ?                        
                                <div className="text" dangerouslySetInnerHTML={ { __html: showData.text_2.replaceAll('&#34;', '"') } } /> : null
                            } 
                        </div> : null
                    }    
                    {
                        showData.title_4 || showData.text_3 ?          
                        <div className="">    
                            {
                                showData.title_4 ? <div className="subtitle">{ showData.title_4 }</div> : null
                            } 
                            {
                                showData.text_3 ?                        
                                <div className="text" dangerouslySetInnerHTML={ { __html: showData.text_3.replaceAll('&#34;', '"') } } /> : null
                            } 
                        </div> : null
                    }   
                </div>
            </div>
            <div className="background_image" style={ { backgroundImage: "url('" + showData.bg_img + "')" } }>
                {/* <img alt="img" src={ showData.bg_page } className="img_home" /> */}
            </div>
        </div>
    )
}