import { useState, useEffect } from "react";

import './FixedFooter.css';
import { SvgLogotipo } from "components/SvgFile";

export default function FixedFooter(props){
    
    return(
        <footer className="FixedFooter">
            <div className="div_top">      
                <div className="container div_end_flex">
                    <div className="div_left">
                        <div className="footer_div_logotipo">
                            <img src="./logo192.png" className="footer_logotipo" />
                        </div> 
                        <div className="list_social_network">
                            <div className="">
                                <div className="">Redes Sociais</div>  
                            </div>
                            <div className="div_list_rede_social">
                                <a href="/" target="_blank">              
                                    <div className="bg_icon">
                                        <img alt="instagram" src="./assets/insta.svg" className="icons_footer" />
                                    </div>
                                </a>
                                <a href="/" target="_blank">                          
                                    <div className="bg_icon">
                                        <img alt="youtube" src="./assets/youtube.svg" className="icons_footer" /> 
                                    </div>
                                </a>
                                <a href="/" target="_blank">                               
                                    <div className="bg_icon">
                                        <img alt="facebook" src="./assets/face.svg" className="icons_footer" />
                                    </div> 
                                </a>                    
                                <a href="/" target="_blank">             
                                    <div className="bg_icon">
                                        <img alt="twitter" src="./assets/twitter.svg" className="icons_footer" />
                                    </div>       
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="div_right">
                        <div className="div_menu_site">
                            <div className="space_footer">
                                <div className="name_menu">Perguntas frequentes</div>
                                <div className="name_menu">Política de privacidade</div>
                                <div className="name_menu">Professores</div>
                            </div>   
                            <div className="space_footer">
                                <a href="/index" className="page_site" id="page_site" onClick={ ()=>{ props.CkickPage('index'); props.animateScroll() } }>
                                    <div className="name_menu">Home</div>
                                </a>
                                <a href="/howworks" className="page_site" onClick={ ()=>{ props.CkickPage('howworks'); } }>
                                    <div className="name_menu">Como funciona</div>
                                </a>
                                <a href="/courses" className="page_site" onClick={ ()=>{ props.CkickPage('courses'); } }>
                                    <div className="name_menu">Cursos</div>
                                </a>
                            </div> 
                            <div className="space_footer">
                                <a href="/mentoring" className="page_site" onClick={ ()=>{ props.CkickPage('mentoring'); } }>
                                    <div className="name_menu">Mentoria</div>
                                </a>
                                <a href="/rolo" className="page_site" onClick={ ()=>{ props.CkickPage('rolo'); } }>
                                    <div className="name_menu">Rolo</div>
                                </a>
                            </div> 
                        </div>
                        <div className="footer_payment">
                            <div className="">
                                <img alt="payment" src="./assets/site-seguro.svg" className="icons_payment" /> 
                            </div>
                            <div className="">
                                <div className="">Forma de pagamento</div>
                                <div className="div_list_icons">
                                    <div className="">
                                        <img alt="payment" src="./assets/cartao.svg" className="icons_type_payment" /> 
                                    </div>
                                    <div className="">
                                        <img alt="payment" src="./assets/pix.svg" className="icons_type_payment" />
                                    </div>
                                    <div className="">
                                        <img alt="payment" src="./assets/boleto.svg" className="icons_type_payment" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="div_end">
                <div className="container div_end_flex">
                    <div className="">
                        Rua Jovino do Rosário, 1674 - Boa Vista - Curitiba - PR - 82560-435
                    </div>
                    <div className="">
                        © 2017 - 2023 Crazy Pixel School LTDA - CNPJ: 45.033.422/0001-00
                    </div>
                </div>
            </div> */}
        </footer>
    )
}