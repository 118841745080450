import { useState, useEffect, useRef } from "react";

import './Depositions.css';

import { GetDataPageID, RegisterDataPage } from "interface/Data";

import { SvgSetaRight } from "components/SvgFile";

export default function ModelPages_Depositions(props){

    const textHeight = useRef([]);
    
    const [ showData, setShowData ] = useState(GetDataPageID(props.type, props.index));
    const [ height, setHeight ]     = useState(0);
    const [ checked, setChecked ]   = useState(0);

    useEffect(()=>{
        RegisterDataPage(props.type, setShowData);

        NextImg(0);
    }, []);
    
    useEffect(()=>{
        setShowData(GetDataPageID(props.type, props.index));
    }, [showData]);
    
    useEffect(()=>{
        setInterval(() => {
            setChecked(1);
            if(textHeight?.current){
                setHeight(textHeight?.current.clientHeight + 120);
                window.onresize = function(){
                    setChecked(1);
                    setHeight(textHeight?.current.clientHeight + 120);
                };
            }
        }, 100);   
    }, [checked]); 

    function AltPosition_prev(){
        var stream = document.querySelector('.div_carousel');
        var items = document.querySelectorAll('.gallery__item');
        
        var prev = document.querySelector('.gallery__prev');
        NextImg(1);

        stream.insertBefore(items[items.length - 1], items[0]);
        items = document.querySelectorAll('.gallery__item');
    }

    function AltPosition_next(){
        var stream = document.querySelector('.div_carousel');
        var items  = document.querySelectorAll('.gallery__item');
        var next   = document.querySelector('.gallery__next');
        NextImg(1);

        stream.appendChild(items[0]);
        items = document.querySelectorAll('.gallery__item');
    }

    function NextImg(type){
        if(document.querySelector('.gallery__item')){
            var stream = document.querySelector('.div_carousel');
            var items  = document.querySelectorAll('.gallery__item');
            var next   = document.querySelector('.gallery__next');

            // if(type == 1){
            //     setTimeout(() => {                  
            //         setInterval(() => {
            //             stream.appendChild(items[0]);
            //             items = document.querySelectorAll('.gallery__item');
            //         }, 3000);
            //     }, 1500);
            // }else {        
            //     setInterval(() => {
            //         if(document.querySelector('.gallery__item')){
            //             console.log(document.querySelector('.gallery__item'));
            //             stream.appendChild(items[0]);
            //             items = document.querySelectorAll('.gallery__item');
            //         }
            //     }, 3000);
            // }
        }
    }

    return(
        <div className="ModelPages_Depositions" style={ { background: showData.bg_color } }>
            <div className="container show_depositions">
                <div className="title">Depoimentos</div>
                <div className="show_img" style={ { height: height + 'px' } }>

                    <div className="gallery__prev" onClick={ ()=>{ AltPosition_prev('prev') } }>
                        <div className="icons_carousel_prev">
                            <SvgSetaRight color="#ffffff" className="icons_galerry icons_galerry_next" />
                        </div>
                    </div>
                    <div className="gallery__next" onClick={ ()=>{ AltPosition_next('next') } }>
                        <div className="icons_carousel_next">
                            <SvgSetaRight color="#ffffff" className="icons_galerry" />
                        </div>
                    </div>
                    <div className="div_carousel">
                        {
                            showData.contents.map((key, index)=>{ 
                                return (    
                                    <div className="gallery__item" key={ index }>
                                        <div className="show_gallery_data" ref={ textHeight }>
                                            <div className="img">
                                                <img alt="profile" src={ key.file } className="img_profile" />
                                            </div>
                                            <div className="name">{ key.name }</div>
                                            <div className="biography" dangerouslySetInnerHTML={ { __html: key.text.replaceAll('&#34;', '"') } } />
                                            <div className="area">
                                                { key.area }
                                                <div className="date">{ key.date }</div>
                                            </div>
                                        </div>
                                    </div> 
                                )
                            })
                        } 
                    </div>
                </div>
            </div>
        </div>
    )
}